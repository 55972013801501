import { Component } from '@angular/core';
import json from '../json/cardData.json';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})

export class AppComponent  {
  name: string;
  noData: boolean;
  slug: string;
  public cardData:{greeting:string}[];

  constructor() {
    this.noData = false;
  }

  ngOnInit() {
    this.getCardData();
  }

  getCardData(){
    //TODO Get slug from URL
    const pathArray = window.location.pathname.split('/');
    this.slug = pathArray[1];

    if(this.slug !== ""){
      let array = json.filter( record => record.slug === this.slug);

      //Select Data from json based on slug
      if(array === undefined || array.length == 0){
        this.noData = true;
      }
      else{
        this.noData = false;
        //Set cardData
        this.cardData = array;
      }  
    }
    else{
      this.noData = true;
    }

  }
}
