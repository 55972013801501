import { Component, HostBinding, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-open-close',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        top: 0, 
        opacity: 1,
        zIndex: 10,
      })),
      state('closed', style({
        top: '-100%',
        opacity: .75,
        zIndex: 15,
        justifyContent: 'flex-end',
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  templateUrl: 'open-close.component.html',
  styleUrls: ['open-close.component.css'],
  
})
export class OpenCloseComponent {
  isOpen = true;
  @HostBinding('class.closed') get isClosed() { return !this.isOpen; }

  @Input() bgColor : string;

  toggle() {
    this.isOpen = !this.isOpen;
  }

}